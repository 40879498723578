// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAoPJiVAjX_eAyfpgSmgmHpCR_gOdrZcbA",
  authDomain: "insuranceseo13clone.firebaseapp.com",
  projectId: "insuranceseo13clone",
  storageBucket: "insuranceseo13clone.appspot.com",
  messagingSenderId: "975233240982",
  appId: "1:975233240982:web:99551daaaa8a87e91897b7"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;